﻿"use strict";

ejs.delimiter = '$';
ejs.openDelimiter = '{';
ejs.closeDelimiter = '}';

let _map = null;
let markers = [];
let autocomplete;
let countryRestrict = { 'country': 'uk' };
let countryRestrict2 = { 'country': 'ie' };

const SearchEnum = Object.freeze({ "All": 0, "PP": 1, "PayPoint": 2 });

// Create the search box and link it to the UI element.
let _input = document.getElementById('search-box');
let _searchMarker = null;

let _directionsService = null;
let _directionsDisplay = null;
let directionsRendererOptions = {
    suppressMarkers: true
};

let paddyPowerActiveIcon;
let paddyPowerInactiveIcon;
let payPointActiveIcon;
let payPointInactiveIcon;

let storeName = '';
let storeId = 0;
let latlong = {
    lat: '',
    lng: ''
};
let store;
let startPoint;
let endPoint;
let latlongs = {
    lat: 0,
    lng: 0
}
let oldIndex = -1,
    oldStoreId = -1,
    currentIndex;

let activeSearch = SearchEnum.All;
const fov = 90;
const pitch = 0;

// Old Scope Variables
let pageScope = {
    srch: {},
    isStorePage: false,
    isStoreIdPage: false,
    isLocationPage: false,
    showDirections: false,
    travelMode: 'DRIVING',
    isNearest: false,
    latlongs: latlongs,
    mapUrl: '',
    stores: []
};


//Private Functions
function initialise() {

    const path = location.pathname;
    // /store/{id}/{name}
    const oldStoreRegex = /\/store\/(.+)/i;
    // /store/{id}/{name}
    const newStoreRegex = /\/store\/(\d+)\/(.+)/i;

    if (path === '/') {
        pageScope.isStorePage = false;
        pageScope.isStoreIdPage = false;
        pageScope.isLocationPage = false;
    } else if (newStoreRegex.test(path)) {
        let match = newStoreRegex.exec(path);

        pageScope.isStorePage = false;
        pageScope.isStoreIdPage = true;
        pageScope.isLocationPage = false;

        storeId = parseInt(match[1]);
        storeName = match[2];
    } else if (oldStoreRegex.test(path)) {
        let match = oldStoreRegex.exec(path);

        pageScope.isStorePage = true;
        pageScope.isStoreIdPage = false;
        pageScope.isLocationPage = false;

        storeName = match[1];
    } else {
        pageScope.isStorePage = false;
        pageScope.isLocationPage = false;
    }

    loadMap();
}

// Async loads the maps API JS.
function loadMap() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `//maps.googleapis.com/maps/api/js?language=en-GB&region=GB&libraries=geometry,places&key=${GMapsApiKey}&callback=initialiseMap`;
    script.async = true;
    document.body.appendChild(script);
}

function initialiseMap() {
    let latlng;

    if (pageScope.isLocationPage) {
        latlng = new google.maps.LatLng(latlong.lat, latlong.lng);
    } else {
        latlng = new google.maps.LatLng(51.5001754, -0.1332326000000421);
    }

    let zoom = 17;

    let myOptions = {
        zoom: zoom,
        center: latlng,
        disableDoubleClickZoom: true,
        disableDefaultUI: false,
        mapTypeControl: false,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        }
    };

    _map = new google.maps.Map(document.getElementById('map'), myOptions);
    _map.set('lastZoom', _map.getZoom());

    // Setup event listeners & autocomplete
    setupAutoComplete();

    _map.setCenter(latlng);
    setSearchMarker(latlng);
    search(latlng, false);

    if (!pageScope.isStorePage && !pageScope.isStoreIdPage && !pageScope.isLocationPage) {
        // Geolocate user.
        geoLocate();
    }
}

/**
 * Sets up the autocomplete service for the placenames.
 */
function setupAutoComplete() {

    //// Create the autocomplete object and associate it with the UI input control.
    //// Restrict the search to the default country, and to place type 'cities'.
    autocomplete = new google.maps.places.Autocomplete(_input, {
            componentRestrictions: {
                country: ['uk', 'ie']
            }
    });

    autocomplete.addListener('place_changed', onPlaceChanged);
}

// When the user selects a city, get the place details for the city and
// zoom the map in on the city.
function onPlaceChanged() {
    const place = autocomplete.getPlace();
    setPlace(place);
}

/**
 * Sets the location for the search pin.
 */
function setSearchMarker(location) {

    if (_searchMarker === null) {
        _searchMarker = new google.maps.Marker({
            map: _map,
            position: location,
            raiseOnDrag: false,
            draggable: false,
            visible: true,
            clickable: false
        });

        // Attaches a drag end event to the marker.
        google.maps.event.addListener(_searchMarker, 'dragend', () => {
            try {
                const point = _searchMarker.getPosition();
                search(point, false); // Do we want to search if this is dragged?

                _map.panTo(point);
            } catch (e) {
            }
        });
    } else {
        _searchMarker.setPosition(location);
    }
}

/**
 * Performs a search against the api for stores and 
 * then updates the results list and add the markers to the map.
 */
function search(location, isNearest) {
    let request = {
        Latitude: location.lat(),
        Longitude: location.lng()
    }
    let endpoint = '/api/store/FindStores';

    if (pageScope.isStorePage) {
        request.storeName = storeName;
        endpoint = '/api/store/FindStoresByName';
    } else if (pageScope.isStoreIdPage) {
        request.storeId = storeId;
        endpoint = '/api/store/FindStoresById';
    }

    fetch(endpoint, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
        .then(res => res.json())
        .then((data) => {
            pageScope.isNearest = isNearest;

            setDirectionsButtonVisibility(pageScope.isNearest);

            if (data) {
                pageScope.stores = data;
                renderStoreList(data);

                if (pageScope.isStorePage || pageScope.isStoreIdPage) {
                    if (pageScope.stores.length > 0 && pageScope.stores[0].storeName === storeName) {
                        setSearchMarker(new google.maps.LatLng(pageScope.stores[0].latitude, pageScope.stores[0].longitude));
                    }
                    setupStoreMeta(pageScope.stores[0]);
                }

                oldIndex = 0;
                oldStoreId = pageScope.stores[0].id;
                clearMarkers();
                if (pageScope.stores.length > 0) {
                    createMarkers(data);
                }

                filterResults(activeSearch);

            } else {
                pageScope.stores = [];
                oldIndex = 0;
                oldStoreId = -1;
                clearMarkers();
            }
        });
}

function setupStoreMeta(store) {
    if (store.payPoint) {
        document.title = 'Paddy Power Shop Locator – Betting Shops Near Me';
        document.head.children.description.content = `Where’s Paddy? It would be a terrible kids book, 
        sure, but this store locator page will help you find your way to your nearest Paddy Power betting shop!`;
    } else {
        document.title = `${store.storeName} Betting Shop – Paddy Power Shop Locator`;
        document.head.children.description.content = `Drop in to Paddy Power ${store.storeName} for the ultimate betting shop experience!
        Place bets on football, racing, lotto numbers and watch live sport free!`;
    }
    
}

function setDirectionsButtonVisibility(display) {

    const elem = document.querySelector('#direction-actions');
    const buttons = document.querySelectorAll('.directions-button');

    if (display === true) {
        show(elem);

        buttons.forEach((button) => {
            show(button);
        });
    } else {
        hide(elem);

        buttons.forEach((button) => {
            hide(button);
        });
    }
}

function renderStoreList(stores) {
    if (stores != null && stores.length > 0) {
        let template = document.getElementById('result-template').innerHTML;
        let html = '';

        stores.forEach((store, i) => {
            html += ejs.render(template, { data: store, isFirst: (i === 0 ? true : false) });
        });

        document.getElementById('map-results').innerHTML = html;

        // Add store on clicks
        const storeElems = document.querySelectorAll('.map-result');
        storeElems.forEach((store, i) => {
            store.addEventListener('click', function (e) {

                const id = store.id;
                const storeId = id.replace(/result-/i, '');

                selectStore(id, parseInt(storeId));
            });
        });

        // Add get directions on clicks
        const directionElements = document.querySelectorAll('.directions-button');
        directionElements.forEach((directionButton, i) => {
            directionButton.addEventListener('click', (e) => {
                e.stopPropagation();

                const store = pageScope.stores[i];
                getDirections(pageScope.travelMode, store.id);
            });
        });
    }
}

/**
 * Geolocation code used to locate where user is in the world
 */
function geoLocate() {
    // Try HTML5 geolocation
    if (navigator.geolocation) {

        navigator.geolocation.getCurrentPosition((position) => {
            const pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            _map.setCenter(pos);
            setSearchMarker(pos);
            search(pos, true);

        }, () => {
            handleNoGeolocation(true);
        });

    } else {
        // Browser doesn't support Geolocation
        handleNoGeolocation(false);
    }

}
function handleNoGeolocation(errorFlag) {
    let content = '';

    if (errorFlag) {
        content = 'Error: The Geolocation service failed.';
    } else {
        content = 'Error: Your browser doesn\'t support geolocation.';
    }

    let options = {
        map: _map,
        position: new google.maps.LatLng(51.5001754, -0.1332326000000421),
        content: content
    };

    _map.setCenter(options.position);
};

// Clears the markers from the map.
function clearMarkers() {
    markers.forEach(marker => {
        if (marker) {
            marker.setMap(null);
        }
    });

    markers = [];
}


/**
 * Creates a marker for each item in the stores array.
 * @param {array} stores - List of stores.
 */
function createMarkers(stores) {
    const bounds = new google.maps.LatLngBounds();

    if (_searchMarker) {
        bounds.extend(_searchMarker.getPosition());
    }

    paddyPowerActiveIcon = {
        url: '/img/paddypower_active.png',
        scaledSize: new google.maps.Size(70, 70)
    };
    paddyPowerInactiveIcon = {
        url: '/img/paddypower_inactive.png',
        scaledSize: new google.maps.Size(70, 70)
    };
    payPointActiveIcon = {
        url: '/img/paypoint_active.png',
        scaledSize: new google.maps.Size(70, 70)
    };
    payPointInactiveIcon = {
        url: '/img/paypoint_inactive.png',
        scaledSize: new google.maps.Size(70, 70)
    };

    stores.forEach((currentStore, i) => {
        // Use marker animation to drop the icons incrementally on the map.
        const latLong = new google.maps.LatLng(currentStore.latitude, currentStore.longitude);

        bounds.extend(latLong);

        const icon = getMarkerIcon(currentStore.payPoint, oldStoreId === stores[i].id);

        markers[i] = new google.maps.Marker({
            icon: icon,
            position: latLong,
            map: _map
        });

        if (i === 0) {
            markers[0].setZIndex(9999);
        }

        google.maps.event.addListener(markers[i], 'click', ((marker, i) => {
            return function () {
                selectStore(`result-${stores[i].id}`, stores[i].id);
            };
        })(markers[i], i));

        setTimeout(dropMarker(i), i * 100);
    });

    if (pageScope.isStorePage || pageScope.isStoreIdPage) {
        _map.setCenter(new google.maps.LatLng(stores[0].latitude, stores[0].longitude));
        _map.setZoom(17);
    } else {
        _map.fitBounds(bounds);
    }
    pageScope.isStorePage = false;
    pageScope.isStoreIdPage = false;

}

/**
 * Gets the appropriate icon for the marker.
 * @param {any} payPoint
 * @param {any} selected
 */
function getMarkerIcon(payPoint, selected) {
    if (selected) {
        return payPoint ? payPointActiveIcon : paddyPowerActiveIcon;
    } else {
        return payPoint ? payPointInactiveIcon : paddyPowerInactiveIcon;
    }
}

// Drops result marker onto the map.
function dropMarker(i) {
    return () => {
        markers[i].setMap(_map);
    };
}

/**
 * Selects a store
 * @param {string} id - Id for the store.
 */
function selectStore(id, storeId) {
    const storeIndex = pageScope.stores.findIndex(store => store.id === storeId);
    const store = pageScope.stores[storeIndex];

    clearDirections();

    window.history.pushState(store.storeName, 'Paddy Power Shop Locator', `/store/${store.id}/${store.storeName}`);
    setupStoreMeta(store);

    if (oldStoreId > -1) {
        const oldStoreIndex = pageScope.stores.findIndex(store => store.id === oldStoreId);
        const oldStore = pageScope.stores[oldStoreIndex];

        const icon = getMarkerIcon(oldStore.payPoint, false);
        markers[oldStoreIndex].setIcon(icon);
        markers[oldStoreIndex].setZIndex(0);
    }

    const element = document.getElementById(id);

    if (element.classList.contains('open')) {
        element.classList.remove('open');
    } else {
        const resultElements = document.querySelectorAll(`div.map-result:not(#${id})`);

        resultElements.forEach((resultElement) => {
            resultElement.classList.remove('open');
        });

        element.classList.add('open');
        
        var newIcon = getMarkerIcon(store.payPoint, true);

        markers[storeIndex].setIcon(newIcon);
        markers[storeIndex].setZIndex(9999);

        latlongs.lat = store.latitude;
        latlongs.lng = store.longitude;
        pageScope.mapUrl = store.mapURL;

        setTimeout(() => {
            scrollList(id);
        }, 205);
    }

    oldStoreId = storeId;
}

/**
 * Scroll the result list to put the selected item to the top.
 */
function scrollList(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
}

function clearDirections() {
    pageScope.showDirections = false;

    if (_directionsDisplay !== null) {
        _directionsDisplay.setMap(null);
    }
    _directionsDisplay = null;
}

function getDirections(travelMode, storeId) {
    clearDirections();

    if (_searchMarker !== null) {
        const storeIndex = pageScope.stores.findIndex(store => store.id === storeId);
        const curentStore = pageScope.stores[storeIndex];

        startPoint = _searchMarker.getPosition();
        endPoint = new google.maps.LatLng(curentStore.latitude, curentStore.longitude);

        latlongs.lat = curentStore.latitude;
        latlongs.lng = curentStore.longitude;
        pageScope.mapUrl = curentStore.mapURL;

        _directionsService = new google.maps.DirectionsService();
        _directionsDisplay = new google.maps.DirectionsRenderer(directionsRendererOptions);
        _directionsDisplay.setMap(_map);

        pageScope.showDirections = true;
        calculateAndDisplayRoute(_directionsService, _directionsDisplay, startPoint, endPoint, travelMode);
    } else {
        console.error('No start point for directions definied');
    }
}

/**
 * Calculates and displays routes.
 */
function calculateAndDisplayRoute(directionsService, directionsDisplay, startPoint, endPoint, travelMode) {
    directionsDisplay.setPanel(document.getElementById('directions'));

    directionsService.route({
        origin: startPoint,
        destination: endPoint,
        travelMode: travelMode
    }, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
        } else {
            window.alert(`Directions request failed due to ${status}`);
        }
    });
}

function setPlace(place) {
    if (place.geometry) {
        clearDirections();
        _map.panTo(place.geometry.location);
        _map.setZoom(15);
        setSearchMarker(place.geometry.location);
        search(place.geometry.location);
    } else {
        _input.placeholder = 'Enter a city';
    }
}

function filterResults(searchType) {

    pageScope.stores.forEach((currentStore, i) => {
        
        const storeElem = document.querySelector(`#result-${currentStore.id}`);

        if (searchType !== SearchEnum.All) {

            if (searchType === SearchEnum.PP) {
                if (storeElem.classList.contains('store-paypoint')) {
                    storeElem.classList.add('is-hidden');
                } else {
                    storeElem.classList.remove('is-hidden');
                }
            } else if (searchType === SearchEnum.PayPoint) {
                if (storeElem.classList.contains('store-paddypower')) {
                    storeElem.classList.add('is-hidden');
                } else {
                    storeElem.classList.remove('is-hidden');
                }
            }

            const visible = ((searchType === SearchEnum.PayPoint && currentStore.payPoint) || (searchType === SearchEnum.PP && !currentStore.payPoint));
            markers[i].visible = visible;
            markers[i].setMap(null); // Markers do not refresh properly when using the filter, so we have to set them to null then re-add if needed

            if (visible) {
                markers[i].setMap(_map);
            }

        } else {
            storeElem.classList.remove('is-hidden');
            
            markers[i].visible = true;
            markers[i].setMap(null); // Markers do not refresh properly when using the filter, so we have to set them to null then re-add if needed
            markers[i].setMap(_map);
        }
    });
}

function toogleStoreFilterButtons(activeFilter) {
    let filterButtons = document.querySelectorAll(`li.store-filter:not(${activeFilter})`);
    filterButtons.forEach((button) => {
        button.classList.remove('active-filter');
    });
}

function travelModeChanged (travelMode) {
    if (travelMode) {
        pageScope.travelMode = travelMode;
    }

    pageScope.showDirections ? 
        calculateAndDisplayRoute(_directionsService, _directionsDisplay, startPoint, endPoint, pageScope.travelMode)
        : getDirections(pageScope.travelMode, oldStoreId);
}

const storeFilters = document.querySelectorAll('.store-filter');
storeFilters.forEach((storeFilter) => {
    storeFilter
        .addEventListener('click', (e) => {
            const element = e.currentTarget;
            element.classList.add('active-filter');

            if (element.classList.contains('store-filter-all')) {
                toogleStoreFilterButtons('.store-filter-all');
                activeSearch = SearchEnum.All;
                filterResults(activeSearch);
            } else if (element.classList.contains('store-filter-paddy')) {
                toogleStoreFilterButtons('.store-filter-paddy');
                activeSearch = SearchEnum.PP;
                filterResults(activeSearch);
            } else if (element.classList.contains('store-filter-paypoint')) {
                toogleStoreFilterButtons('.store-filter-paypoint');
                activeSearch = SearchEnum.PayPoint;
                filterResults(activeSearch);
            }

        });
});

const dirButtons = document.querySelectorAll('.global-direction-button');
dirButtons.forEach((dirButton) => {
    dirButton
        .addEventListener('click', (e) => {
            const element = e.currentTarget;
            element.classList.add('active');

            if (element.id === "direction-driving") {
                travelModeChanged("DRIVING");
                toogleDirectionButtons('direction-driving');
            } else if (element.id === "direction-walking") { 
                travelModeChanged("WALKING");
                toogleDirectionButtons('direction-walking');
            }

        });
});

function toogleDirectionButtons(activeDirection) {
    let directionButtons = document.querySelectorAll(`.global-direction-button:not(#${activeDirection})`);
    directionButtons.forEach((button) => {
        button.classList.remove('active');
    });
}

document.querySelector('#map-results--geolocate-btn').addEventListener('click', geoLocate);

document.querySelector('#direction-open-maps').addEventListener('click', (e) => {

    if (pageScope.mapUrl) {
        window.open(pageScope.mapUrl, '_blank');
    } else {
        let url = `http://www.google.com/maps/place/${latlongs.lat},${latlongs.lng}/${latlongs.lat},${latlongs.lng},17z`;

        window.open(url, '_blank');
    }

});


/**
 * Helper Functions
 */

// Show an element
const show = (elem) => {
    elem.classList.add('is-visible');
};

// Hide an element
const hide = (elem) => {
    elem.classList.remove('is-visible');
};

// Toggle element visibility
const toggleVisibility = (elem) => {
    elem.classList.toggle('is-visible');
};


// Handler
((input) => {
    // store the original event binding function
    let _addEventListener = input.addEventListener;

    function addEventListenerWrapper(type, listener) {

        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.
        if (type == "keydown") {
            let orig_listener = listener;
            listener = (event) => {
                let suggestion_selected = document.querySelector('.pac-item-selected');
                if (event.which == 13 && (suggestion_selected === null || suggestion_selected.textContent.length === 0)) {                    
                    let simulated_downarrow = new KeyboardEvent('keydown', { keyCode: 40, which: 40 });
                    orig_listener.apply(input, [simulated_downarrow]);
                }

                orig_listener.apply(input, [event]);
            };
        }

        // add the modified listener
        _addEventListener.apply(input, [type, listener]);
    }

    input.addEventListener = addEventListenerWrapper;

})(_input);

// OneTrust

function manageCookie() {
    OneTrust.ToggleInfoDisplay();
}

document.querySelector('#ManageCookies').addEventListener('click', manageCookie);


initialise();



window.initialiseMap = initialiseMap;