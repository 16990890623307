﻿// Custom JS imports
import '../js/index.js'

// Custom CSS imports
import '../css/style.css';
import '../css/changes.css';

console.log('The \'site\' bundle has been loaded!');


